<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
	import {
		mapState,
		mapMutations
	} from 'vuex';
	export default {
		computed: {
			...mapState({
				'lang':state=>state.app.currentLang,
				'en_id':state=>state.app.en_id
			}),
		},
		methods: {
			...mapMutations({
				setLang(commit, lang) {
					commit('app/SET_LANG', lang)
				},
				setWebInfo(commit, webcontent) {
					commit('app/SET_WEB', webcontent)
				},
			}),
		},
		created() {
			if(!this.lang){
				var nvlang = navigator.language;
				if(nvlang){
					this.setLang(nvlang) ;
				}else{
					this.setLang("en-US")
				}
			}
			
			// this.$axios.get("http://localhost:8999/api/website/all_websites?en_id="+this.en_id).then(res=>{
			// 	this.setWebInfo(res.data)
			// })
			// this.$axios.get("https://jesway.cn/api/website/all_websites?en_id="+this.en_id).then(res=>{
			// 	this.setWebInfo(res.data)
			// 	console.log(JSON.stringify(res))
			// })
		},
	}
</script>