import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		redirect:'/Home/'
	},
	{
		path: '/Home/',
		name: 'Home',
		// component: Home
		meta: {
			title: "Home - 首页",
		},
		component: (resolve) => require(["@/views/Home.vue"], resolve),
	},
	{
		path: '/Competencies/',
		name: 'Competencies',
		meta: {
			title: 'Competencies'
		},
		// component: () => import('../views/Competencies.vue')
		component: (resolve) => require(["@/views/Competencies.vue"], resolve),
	},
	{
		path: '/Products/',
		name: 'Products',
		meta: {
			title: 'Products'
		},
		// component: () => import('../views/Products.vue')
		component: (resolve) => require(["@/views/Products.vue"], resolve),
	},
	{
		path: '/ProductsDetail/',
		name: 'ProductsDetail',
		meta: {
			title: 'ProductsDetail'
		},
		// component: () => import('../views/ProductsDetail.vue')
		component: (resolve) => require(["@/views/ProductsDetail.vue"], resolve),
	},
	{
		path: '/MaterialMore',
		name: 'MaterialMore',
		meta: {
			title: 'MaterialMore'
		},
		// component: () => import('../views/MaterialMore.vue')
		component: (resolve) => require(["@/views/MaterialMore.vue"], resolve),
	},
	{
		path: '/ContactUs/',
		name: 'ContactUs',
		meta: {
			title: 'ContactUs'
		},
		// component: () => import('../views/ContactUs.vue')
		component: (resolve) => require(["@/views/ContactUs.vue"], resolve),
	},
	{
		path: '/AboutUs/',
		name: 'AboutUs',
		meta: {
			title: 'AboutUs'
		},
		// component: () => import('../views/AboutUs.vue')
		component: (resolve) => require(["@/views/AboutUs.vue"], resolve),
	},
	{
		path: '/userdiy/',
		name: 'userdiy',
		meta: {
			title: 'userdiy'
		},
		// component: () => import('../views/diy.vue')
		component: (resolve) => require(["@/views/diy.vue"], resolve),
	},
	{
		path: '/enterid/:enid',
		name: 'enterid',
		meta: {
			title: 'enterid'
		},
		// component: () => import('../views/diy.vue')
		component: (resolve) => require(["@/views/enterid.vue"], resolve),
	},
	{
		path: '/privacy-policy/',
		name: 'privacy-policy',
		meta: {
			title: 'privacy-policy'
		},
		// component: () => import('../views/diy.vue')
		component: (resolve) => require(["@/views/privacy-policy.vue"], resolve),
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
